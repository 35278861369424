import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';

import spacings from 'theme/spacings';
import breakpoints from 'theme/breakpoints';
import useWindowSize from 'hooks/windowSize';
import { getMatchFacts } from 'libs/api/Home';
import { formatDate, getTodayDate } from 'theme/utils/functions';

import Text from 'components/foundation/Text';
import Article from 'components/common/Article';
import Grid from 'components/foundation/layout/Grid';
import SectionTitle from 'components/common/SectionTitle';

import 'swiper/css/pagination';
import 'swiper/swiper-bundle.css';

import * as S from './styles';

const INITIAL_STATE = {
  error: false,
  activeDay: '',
  allArticles: {},
  loadingData: false,
  abortController: null,
};

const TOTAL_DAYS = 5;
const TOTAL_FUTURE_DAYS = 1;

const ArticleGrid = ({
  limit,
  articles,
  articleProps,
  sectionTitleProps,
  enableSelect = false,
}) => {
  const today = getTodayDate();
  const todayFormated = formatDate({ date: today, format: 'YYYY-MM-DD' });
  const [stateLocal, setStateLocal] = useState({
    ...INITIAL_STATE,
    activeDay: todayFormated,
    allArticles: {
      [todayFormated]: articles,
    },
  });

  const { itemsPerView } = useWindowSize();

  const { error, activeDay, loadingData, allArticles, abortController } =
    stateLocal;

  let selectOptions = [];

  if (enableSelect) {
    selectOptions = Array.from({ length: TOTAL_DAYS }, (_, index) => {
      const day = today.set(
        'date',
        today.get('date') - (index - TOTAL_FUTURE_DAYS)
      );

      return {
        label: formatDate({ date: day, format: 'DD/MM/YYYY' }),
        value: formatDate({ date: day, format: 'YYYY-MM-DD' }),
      };
    });
  }

  useEffect(
    () => () => {
      if (abortController) {
        abortController.abort();
      }
    },
    [abortController]
  );

  return (
    <S.ArticleGridWrapper>
      <SectionTitle
        {...sectionTitleProps}
        {...(enableSelect
          ? {
              selectOptions,
              handleChangeDropdown: async e => {
                const selectedDate = e.target.value;

                if (abortController) {
                  abortController.abort();
                }

                const newAbortController = new AbortController();

                setStateLocal(state => ({
                  ...state,
                  error: null,
                  loadingData: true,
                  abortController: newAbortController,
                }));

                try {
                  const matchFacts = await getMatchFacts({
                    date: selectedDate,
                    signal: newAbortController.signal,
                  });

                  setStateLocal(state => ({
                    ...state,
                    loadingData: false,
                    activeDay: selectedDate,
                    allArticles: {
                      ...state.allArticles,
                      [selectedDate]: matchFacts?.noticias || [],
                    },
                  }));
                } catch (er) {
                  setStateLocal(state => ({
                    ...state,
                    error: true,
                    loadingData: false,
                  }));
                }
              },
            }
          : {})}
      />

      <Grid.Container
        $noGutter={{
          xs: true,
          md: true,
        }}
      >
        <S.Row>
          {loadingData && !error ? (
            <>
              {Array.from({ length: itemsPerView }).map((_, index) => (
                <S.Col
                  key={`col-${index}`}
                  limit={itemsPerView}
                >
                  <Skeleton
                    width='100%'
                    height={200}
                    style={{ marginBottom: spacings.md }}
                  />
                  <Skeleton
                    count={3}
                    height={15}
                    width='100%'
                    style={{ marginBottom: spacings.sm }}
                  />
                </S.Col>
              ))}
            </>
          ) : (
            <>
              {error ? (
                <S.Col
                  $cssMinHeight={{
                    xs: '230px',
                    md: '280px',
                  }}
                >
                  <Text
                    as='span'
                    $csscolor='950'
                    $variant={{ typography: 'bodyTextXXXSmall' }}
                  >
                    Ops! Ocorreu um erro ao tentar carregar as notícias! Tente
                    novamente mais tarde.
                  </Text>
                </S.Col>
              ) : (
                <>
                  <Swiper
                    grabCursor
                    speed={500}
                    spaceBetween={10}
                    className='live-odds__slider'
                    modules={[Pagination]}
                    slidesPerView={'auto'}
                    breakpoints={{
                      [breakpoints.xl]: {
                        slidesPerView: 4,
                      },
                      [breakpoints.lg]: {
                        slidesPerView: 3,
                      },
                      [breakpoints.md]: {
                        slidesPerView: 2,
                      },
                    }}
                    autoplay={{
                      delay: 10000,
                      disableOnInteraction: false,
                    }}
                    pagination={{
                      clickable: true,
                    }}
                  >
                    {allArticles[activeDay]?.map((article, index) =>
                      index < limit ? (
                        <SwiperSlide
                          className='article-grid__slider-item'
                          key={`article-${article?.noticia?.uri || article?.uri || index}`}
                        >
                          <Article
                            data={article?.noticia || article || {}}
                            {...articleProps}
                          />
                        </SwiperSlide>
                      ) : null
                    )}
                  </Swiper>
                </>
              )}
            </>
          )}
        </S.Row>
      </Grid.Container>
    </S.ArticleGridWrapper>
  );
};

ArticleGrid.propTypes = {
  enableSelect: PropTypes.bool,
  limit: PropTypes.number.isRequired,
  articles: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  articleProps: PropTypes.shape({}).isRequired,
  sectionTitleProps: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    viewMore: PropTypes.shape({
      link: PropTypes.string,
    }),
  }).isRequired,
};

export default React.memo(ArticleGrid);
