import React from 'react';
import Link from 'next/link';
import Image from 'next/image';
import Script from 'next/script';
import PropTypes from 'prop-types';

import breakpoints from 'theme/breakpoints';

import * as S from './styles';

const StaticBanner = ({
  link,
  title,
  sticky,
  className,
  mobileImage,
  desktopImage,
  priority = false,
  bannerFormat = 'horizontal',
}) => (
  <S.StaticBannerWrapper
    $sticky={sticky}
    className={className}
  >
    <Link
      href={link}
      title={title}
      target='_blank'
      prefetch={false}
    >
      <S.Picture $bannerFormat={bannerFormat}>
        <source
          srcSet={desktopImage}
          media={`(min-width: ${breakpoints.md}px)`}
        />

        <Image
          fill
          alt={title}
          quality={100}
          src={mobileImage}
          priority={priority}
          className='image-fill'
        />
      </S.Picture>
    </Link>

    <Script
      type='text/javascript'
      src='https://js.nsxafiliados.com/javascript.php?prefix=bG5FcVwwsuvfkb3gg_bGdGNd7ZgqdRLk&media=5&campaign=1'
    />
  </S.StaticBannerWrapper>
);

StaticBanner.propTypes = {
  sticky: PropTypes.bool,
  priority: PropTypes.bool,
  className: PropTypes.string,
  link: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  mobileImage: PropTypes.string.isRequired,
  desktopImage: PropTypes.string.isRequired,
  bannerFormat: PropTypes.oneOf(['horizontal', 'square']),
};

export default React.memo(StaticBanner);
