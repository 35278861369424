import React, { useState } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import PropTypes from 'prop-types';

import { MATCH_STATUS } from 'libs/constants';
// import VARIAVEIS_GLOBAIS from 'theme/utils/globalVariables';
import {
  isEmpty,
  kebabCase,
  formatDate,
  isTodayDate,
} from 'theme/utils/functions';

import Text from 'components/foundation/Text';

import * as S from './styles';

const shieldPlaceholder = '/images/logos/shield-team-placeholder.svg';

const Team = ({ team } = {}) => {
  const [teamLogo, setTeamLogo] = useState(team?.logo);

  return (
    <S.Team>
      <Image
        width={18}
        height={18}
        src={teamLogo}
        alt={team?.name || 'Logo do time'}
        onLoad={el => {
          if (el.target.naturalWidth <= 5) {
            setTeamLogo(shieldPlaceholder);
          }
        }}
        onError={() => {
          setTeamLogo(shieldPlaceholder);
        }}
      />
      <Text
        as='span'
        $csscolor='900'
        className='team__name'
        $variant={{ typography: 'heading5' }}
      >
        {team?.name}
      </Text>
    </S.Team>
  );
};

Team.propTypes = {
  team: PropTypes.shape({
    name: PropTypes.string.isRequired,
    logo: PropTypes.string.isRequired,
  }).isRequired,
};

const FeaturedEventsCard = ({ data = {}, showScores = false } = {}) => {
  const date = data?.startTime;
  const formattedTime = formatDate({
    date,
    format: 'HH:mm',
  });
  const isToday = isTodayDate({ date });
  const formattedDate = isToday
    ? 'HOJE'
    : formatDate({
        date,
        format: 'DD/MM',
      });

  const { competitors } = data || {};
  const [homeTeam, awayTeam] = competitors || [];

  if (data?.error) {
    return '';
  }

  const live = MATCH_STATUS.LIVE.includes(data?.status);

  return (
    <S.FeaturedEventsCardWrapper className='featured-events-card__wrapper'>
      <Link
        target='_top'
        prefetch={false}
        href={`/esportes/jogo/futebol/${kebabCase(data?.competitionName) || kebabCase(data?.competition?.name)}/${data?.slug}/`}
      >
        {isEmpty(data?.competitionName) ? null : (
          <S.Header>
            <Text
              as='span'
              $csscolor='900'
              $variant={{
                typography: 'bodyTextXXSmall',
              }}
            >
              {data.competitionName}
            </Text>
          </S.Header>
        )}

        <S.Content>
          <S.Time>
            {live ? (
              <Text
                as='span'
                className='featured-events__live'
                $variant={{
                  typography: 'bodyTextXXSmall',
                }}
              >
                {live?.minutes}
              </Text>
            ) : (
              <>
                <Text
                  as='time'
                  $csscolor='900'
                  datetime={date}
                  className='featured-events__date'
                  $variant={{
                    typography: 'bodyTextXXSmall',
                  }}
                  {...(isEmpty(date)
                    ? {}
                    : {
                        title: formatDate({
                          date,
                          format: 'DD [de] MMMM [de] YYYY [às] HH:mm',
                        }),
                      })}
                >
                  {formattedDate}
                </Text>
                <Text
                  as='time'
                  datetime={date}
                  $csscolor='900'
                  $variant={{
                    typography: 'bodyTextXXSmall',
                  }}
                  {...(isEmpty(date)
                    ? {}
                    : {
                        title: formatDate({
                          date,
                          format: 'DD [de] MMMM [de] YYYY [às] HH:mm',
                        }),
                      })}
                >
                  {formattedTime}
                </Text>
              </>
            )}
          </S.Time>

          <S.Teams>
            {isEmpty(homeTeam) ? null : <Team team={homeTeam} />}
            {isEmpty(awayTeam) ? null : <Team team={awayTeam} />}
          </S.Teams>

          <S.Scoreboard>
            {live || showScores ? (
              <>
                <Text
                  as='span'
                  $variant={{ typography: 'heading5' }}
                  className='featured-events__live-points'
                >
                  {homeTeam.score}
                </Text>
                <Text
                  as='span'
                  $variant={{ typography: 'heading5' }}
                  className='featured-events__live-points'
                >
                  {awayTeam.score}
                </Text>
              </>
            ) : null}
          </S.Scoreboard>
        </S.Content>
      </Link>
    </S.FeaturedEventsCardWrapper>
  );
};

FeaturedEventsCard.propTypes = {
  showScores: PropTypes.bool,
  data: PropTypes.shape({
    startTime: PropTypes.string,
    competitionName: PropTypes.string,
    slug: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    competitors: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        logo: PropTypes.string.isRequired,
        score: PropTypes.number,
      })
    ).isRequired,
  }).isRequired,
};

export default React.memo(FeaturedEventsCard);
