import {
  isNull,
  isEmpty,
  isObject,
  formatDate,
  getTodayDate,
} from 'theme/utils/functions';
import fetchAPIWPRestGet from '..';

const getHighlightNews = async ({ ssr = false, customHeaders } = {}) => {
  try {
    const data = await fetchAPIWPRestGet({
      ssr,
      path: '/home',
      customHeaders,
    });

    return data || {};
  } catch (error) {
    console.error(
      'An error occurred while trying to return data: getHighlightNews path -> /home',
      error
    );

    return {
      error,
      erro: true,
    };
  }
};

export default getHighlightNews;

export const getLastNews = async ({
  params,
  ssr = false,
  customHeaders,
} = {}) => {
  try {
    const data = await fetchAPIWPRestGet({
      ssr,
      customHeaders,
      path: `/last-news${isObject(params) ? `?${new URLSearchParams(params)}` : ''}`,
    });

    return data || [];
  } catch (error) {
    console.error(
      'An error occurred while trying to return data: getLastNews path -> /last-news',
      error
    );

    return {
      error,
      erro: true,
    };
  }
};

export const getContent = async ({
  params,
  ssr = false,
  customHeaders,
} = {}) => {
  try {
    const data = await fetchAPIWPRestGet({
      ssr,
      customHeaders,
      path: `/content${isObject(params) ? `?${new URLSearchParams(params)}` : ''}`,
    });

    return data || [];
  } catch (error) {
    console.error(
      `An error occurred while trying to return data: getContent path -> /content${`${isObject(params) ? `?${new URLSearchParams(params)}` : ''}`}`,
      error
    );

    return {
      error,
      erro: true,
    };
  }
};

export const getPreviewData = async ({
  params,
  ssr = false,
  customHeaders,
} = {}) => {
  try {
    const data = await fetchAPIWPRestGet({
      ssr,
      customHeaders,
      previewMode: true,
      path: `/post${isObject(params) ? `?${new URLSearchParams(params)}` : ''}`,
    });

    return data || [];
  } catch (error) {
    console.error(
      `An error occurred while trying to return data: getPreviewData path -> /post${`${isObject(params) ? `?${new URLSearchParams(params)}` : ''}`}`,
      error
    );

    return {
      error,
      erro: true,
    };
  }
};

export const getMatchFacts = async ({
  date,
  signal,
  ssr = false,
  customHeaders,
} = {}) => {
  try {
    const params = {
      date: (() => {
        const dateStr =
          isNull(date) || isEmpty(date)
            ? formatDate({ date: getTodayDate(), format: 'YYYY-MM-DD' })
            : date;

        if (!/^\d{4}-\d{2}-\d{2}$/.test(dateStr)) {
          throw new Error('Invalid date format. Expected YYYY-MM-DD');
        }
        return dateStr;
      })(),
    };

    const data = await fetchAPIWPRestGet({
      ssr,
      signal,
      customHeaders,
      path: `/fatos-dos-confrontos${isObject(params) ? `?${new URLSearchParams(params)}` : ''}`,
    });

    return data || {};
  } catch (error) {
    console.error(
      'An error occurred while trying to return data: getMatchFacts path -> /fatos-dos-confrontos',
      error
    );

    return {
      error,
      erro: true,
    };
  }
};
