import {
  KwaiIcon,
  TiktokIcon,
  TwitchIcon,
  ThreadsIcon,
  YoutubeIcon,
  TwitterIcon,
  FacebookIcon,
  InstagramIcon,
} from 'components/common/SocialMediaIcons';

const getSocialIcon = name => {
  const icons = {
    Facebook: FacebookIcon,
    Twitter: TwitterIcon,
    TikTok: TiktokIcon,
    Instagram: InstagramIcon,
    Youtube: YoutubeIcon,
    Kwai: KwaiIcon,
    Twitch: TwitchIcon,
    Threads: ThreadsIcon,
  };

  return icons[name] || null;
};

export default getSocialIcon;
