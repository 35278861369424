import React from 'react';
import Link from 'next/link';
import Image from 'next/image';
import PropTypes from 'prop-types';

import { isEmpty } from 'theme/utils/functions';

import Text from 'components/foundation/Text';
import Dropdown from 'components/common/Dropdown';

import * as S from './styles';

const SectionTitle = ({
  title,
  className,
  badge = '',
  subtitle = '',
  defaultIndex = 1,
  selectOptions = [],
  handleChangeDropdown = () => {},
  viewMore = {
    link: '',
    text: 'Ver mais',
  },
}) => {
  const { link = '', text = 'Ver mais' } = viewMore || {};

  return (
    <S.SectionTitleWrapper className={className}>
      <S.Title>
        <Text
          as='h2'
          $csscolor='900'
          $variant={{
            typography: 'heading2',
          }}
        >
          {link ? (
            <Link
              href={link}
              target='_top'
              prefetch={false}
              className='link-underline'
            >
              {title}
            </Link>
          ) : (
            title
          )}
        </Text>

        {badge ? (
          <Image
            alt='Badge'
            src={badge}
            width={32}
            height={32}
            quality={100}
          />
        ) : null}
      </S.Title>

      {subtitle ? (
        <Text
          as='p'
          $csscolor='900'
          $variant={{
            typography: 'bodyText',
          }}
        >
          {subtitle}
        </Text>
      ) : null}

      {link && isEmpty(selectOptions) ? (
        <S.ViewMore className='view-more__button'>
          <Link
            href={link}
            target='_top'
            prefetch={false}
            data-testid='link-more'
            className='link-underline'
          >
            <Text
              as='span'
              $csscolor='900'
              $variant={{
                typography: 'bodyTextXXXSmall',
              }}
            >
              {text}
            </Text>
          </Link>
        </S.ViewMore>
      ) : null}

      {isEmpty(selectOptions) ? null : (
        <Dropdown
          options={selectOptions}
          setIndexValue={defaultIndex}
          onChange={handleChangeDropdown}
        />
      )}
    </S.SectionTitleWrapper>
  );
};

SectionTitle.propTypes = {
  badge: PropTypes.string,
  subtitle: PropTypes.string,
  className: PropTypes.string,
  defaultIndex: PropTypes.number,
  title: PropTypes.string.isRequired,
  handleChangeDropdown: PropTypes.func,
  selectOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  viewMore: PropTypes.shape({
    link: PropTypes.string,
    text: PropTypes.string,
  }),
};

export default React.memo(SectionTitle);
