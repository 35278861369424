import { useState, useEffect } from 'react';
import breakpoints from 'theme/breakpoints';

function getItemsPerBreakpoint(width) {
  if (!width) return 1; // Default para SSR ou quando width não está definido

  if (width >= breakpoints.xl) return 4; // >= 1280px
  if (width >= breakpoints.lg) return 3; // >= 992px
  if (width >= breakpoints.md) return 2; // >= 768px
  return 1; // < 768px
}

function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
    itemsPerView: 1,
  });
  useEffect(() => {
    function handleResize() {
      const width = window.innerWidth;
      const height = window.innerHeight;

      setWindowSize({
        width,
        height,
        itemsPerView: getItemsPerBreakpoint(width),
      });
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return windowSize;
}
export default useWindowSize;
