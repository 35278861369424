import React from 'react';
import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';

import { isEmpty } from 'theme/utils/functions';

import spacings from 'theme/spacings';
import Grid from 'components/foundation/layout/Grid';
import StaticBanner from 'components/common/StaticBanner';
import FeaturedEvents from 'components/data/FeaturedEvents';
import ArticleHighlight from 'components/common/HomeArticleHighlight';
import DynamicCategoriesList from './DynamicCategoriesList';

const LazyLoadFeaturedVideos = dynamic(
  () => import('components/common/FeaturedVideos/LazyLoadFeaturedVideos'),
  { ssr: false }
);

const LazyLoadArticlesByCategory = dynamic(
  () =>
    import('components/common/ArticlesByCategory/LazyLoadArticlesByCategory'),
  { ssr: false }
);

const Home = ({
  isMobile,
  liveEvents,
  highlightNews,
  youtubeShorts,
  matchsHighlights,
}) => {
  const { hotNews = [], featuredPosts = [], moreNews = [] } = highlightNews;
  const categoriesPagination = {
    subCategorys: [...(hotNews || [])],
    Category: {
      categoryFather: null,
      subCategorys: [...(hotNews || [])],
    },
  };

  const excludePosts = [
    ...featuredPosts.map(n => n.noticia.id),
    ...moreNews.flatMap(
      n => n?.categoria?.news?.map(ne => ne.noticia?.id ?? ne?.id) || []
    ),
  ];

  return (
    <Grid.Container>
      {isEmpty(matchsHighlights) ? null : (
        <FeaturedEvents
          isMobile={isMobile}
          data={matchsHighlights}
        />
      )}

      <ArticleHighlight
        isMobile={isMobile}
        liveEvents={liveEvents}
        youtubeShorts={youtubeShorts}
        highlightNews={highlightNews?.featuredPosts}
      />

      <DynamicCategoriesList
        isMobile={isMobile}
        data={highlightNews?.moreNews}
      />

      <LazyLoadFeaturedVideos />

      {isEmpty(hotNews) ? null : (
        <Grid.Row>
          <Grid.Col $colValue={{ xs: 12, lg: 9 }}>
            <LazyLoadArticlesByCategory
              isHotNews
              showLoadLoreButton
              isMobile={isMobile}
              excludePosts={excludePosts}
              data={categoriesPagination}
              articleProps={{
                showDate: true,
                horizontal: true,
                showExcerpt: true,
                thumbnailSize: 'small',
                titleTypography: 'heading1',
              }}
            />
          </Grid.Col>
          <Grid.Col
            $colValue={{ xs: 12, lg: 3 }}
            $cssMargin={{
              xs: `${spacings.xl} 0 0`,
              lg: `${spacings.xxxl} 0 0`,
            }}
          >
            <StaticBanner
              sticky
              bannerFormat='square'
              title='Betnacional - A Bet do Brasil'
              mobileImage='https://media.nsxafiliados.com/uploads/001x300x250px_1.gif'
              desktopImage='https://media.nsxafiliados.com/uploads/001x300x250px_1.gif'
              link='https://record.nsxafiliados.com/_bG5FcVwwsuvfkb3gg_bGdGNd7ZgqdRLk/1/'
            />
          </Grid.Col>
        </Grid.Row>
      )}
    </Grid.Container>
  );
};

Home.propTypes = {
  isMobile: PropTypes.bool,
  liveEvents: PropTypes.shape({}),
  youtubeShorts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  matchsHighlights: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  highlightNews: PropTypes.shape({
    hotNews: PropTypes.arrayOf(PropTypes.shape({})),
    moreNews: PropTypes.arrayOf(PropTypes.shape({})),
    featuredPosts: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  matchsHome: PropTypes.shape({
    events: PropTypes.shape({}),
    oddsWord: PropTypes.shape({}),
    oddsBrazil: PropTypes.shape({}),
    odds: PropTypes.shape({}).isRequired,
    live_count: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};

export default React.memo(Home);
