const barlowBold = '"Barlow Bold", sans-serif';

const typographyArticle = {
  articleHeading2: {
    xs: {
      fontSize: '2.2rem',
      fontStyle: 'normal',
      fontWeight: 'normal',
      lineHeight: 'normal',
      fontFamily: barlowBold,
    },
    lg: {
      fontSize: '3rem',
    },
  },
  articleHeading3: {
    xs: {
      fontSize: '2rem',
      fontStyle: 'normal',
      fontWeight: 'normal',
      lineHeight: 'normal',
      fontFamily: barlowBold,
    },
    lg: {
      fontSize: '2.6rem',
    },
  },
  articleHeading4: {
    xs: {
      fontSize: '1.8rem',
      fontStyle: 'normal',
      fontWeight: 'normal',
      lineHeight: 'normal',
      fontFamily: barlowBold,
    },
    lg: {
      fontSize: '2.4rem',
    },
  },
  articleHeading5: {
    xs: {
      fontSize: '1.6rem',
      fontStyle: 'normal',
      fontWeight: 'normal',
      lineHeight: 'normal',
      fontFamily: barlowBold,
    },
    lg: {
      fontSize: '2.2rem',
    },
  },
};

export default typographyArticle;
