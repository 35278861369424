import styled, { css } from 'styled-components';
import breakpointsMedia from 'theme/utils/breakpointsMedia';

const arrowRight =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAABCcAAAQnAEmzTo0AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAEdSURBVHgB7ZSxTsJQFIb/cyuTQ2u6YNDkRmC2Dg50kdHRR3B09Qksb+Ab4OrIE+gkPIQhTTS4KXEianu8JSFAIPSe0pFvaO5JTv+v96T3AjvKRjfPg8N668q234GQ/QPdJ9CN6x/T9+fbc16/ghiOp0/G3VEjjPK6xTvw3UYvpeQShKop23k7EQvG43jiu/VHW4lYIJUUEkgktFjUmmFArDwISDjxHFCXgel7ROi8v75EK4LaSXhtqi5KIGW+/Rj277P1/DdVLPryTSjir9l6aURV3bqAkIpDnhnPw2xEzGk0Gg46awVStG57v+rnyaQE68K3EtiEFxbYhhcSSMIzxJedJLyQwBwkbRuesQchf5X0TE3U6Sge9LCjDP4BwZeSOAXHvPIAAAAASUVORK5CYII=';

export const SectionTitleWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    padding-top: ${theme.spacings.sm};
    border-top: 0.8rem solid ${theme.colors.base['100']};
    margin: ${theme.spacings.xxxl} 0 ${theme.spacings.md};

    ${breakpointsMedia({
      lg: css`
        padding-top: ${theme.spacings.md};
      `,
    })}

    p {
      display: none;

      ${breakpointsMedia({
        lg: css`
          display: block;
        `,
      })}
    }
  `}
`;

export const Title = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    gap: ${theme.spacings.sm};

    ${breakpointsMedia({
      lg: css`
        gap: ${theme.spacings.md};
      `,
    })}

    a {
      color: inherit;
      text-decoration: none;
    }
  `}
`;

export const ViewMore = styled.div`
  ${({ theme }) => css`
    a {
      display: flex;
      align-items: center;
      gap: ${theme.spacings.xs};

      &::after {
        content: '';
        width: 1.4rem;
        height: 1.4rem;
        background-size: 1.4rem 1.4rem;
        background-image: url(${arrowRight});

        ${breakpointsMedia({
          lg: css`
            width: 1.6rem;
            height: 1.6rem;
            background-size: 1.6rem 1.6rem;
          `,
        })}
      }
    }
  `}
`;
