import styled, { css } from 'styled-components';

import { TextStyleVariants } from 'components/foundation/Text';

export const DropdownWrapper = styled.div`
  position: relative;
`;

export const DropdownContent = styled.select`
  ${({ theme, $optionActive }) => {
    const $color =
      theme.colors.category[$optionActive?.replaceAll('/', '')] ||
      theme.colors.base['900'];

    return css`
      display: block;
      color: ${$color};
      margin-left: auto;
      border-radius: 3.2rem;
      border-color: ${$color};
      padding: ${theme.spacings.xs} ${theme.spacings.sm};

      &:focus,
      &:active,
      &:hover {
        outline: 1px solid ${$color};
      }

      ${TextStyleVariants({
        $variant: { typography: 'bodyTextXXXSmall' },
      })};
    `;
  }}
`;

export const DropdownItem = styled.option``;
