import typographyArticle from 'theme/typography/article';

const barlowMedium = '"Barlow", sans-serif';
const barlowBold = '"Barlow Bold", sans-serif';
const barlowCondensed = '"Barlow Condensed", sans-serif';

const typographyVariants = {
  heading1: {
    xs: {
      fontWeight: 'normal',
      fontSize: '1.6rem',
      fontStyle: 'normal',
      lineHeight: '2rem',
      fontFamily: barlowBold,
    },
    lg: {
      fontSize: '3.2rem',
      lineHeight: '3.6rem',
    },
  },
  heading1Secondary: {
    xs: {
      fontWeight: 'normal',
      fontSize: '2.4rem',
      fontStyle: 'normal',
      lineHeight: '3.2rem',
      fontFamily: barlowBold,
    },
    lg: {
      fontSize: '3.2rem',
      lineHeight: '3.6rem',
    },
  },
  heading2: {
    xs: {
      fontWeight: 'normal',
      fontSize: '1.6rem',
      fontStyle: 'normal',
      lineHeight: '2rem',
      fontFamily: barlowBold,
    },
    lg: {
      fontSize: '2.4rem',
      lineHeight: '3.2rem',
    },
  },
  heading3: {
    xs: {
      fontWeight: 'normal',
      fontSize: '1.4rem',
      fontStyle: 'normal',
      lineHeight: '1.8rem',
      fontFamily: barlowBold,
    },
    lg: {
      fontSize: '2rem',
      lineHeight: '2.4rem',
    },
  },
  heading4: {
    xs: {
      fontWeight: 'normal',
      fontSize: '1.4rem',
      fontStyle: 'normal',
      lineHeight: '2.4rem',
      fontFamily: barlowBold,
    },
  },
  heading5: {
    xs: {
      fontWeight: 500,
      fontSize: '1.4rem',
      fontStyle: 'normal',
      lineHeight: '1.6rem',
      fontFamily: barlowCondensed,
    },
  },
  bodyText: {
    xs: {
      fontWeight: 500,
      fontSize: '1.4rem',
      lineHeight: '1.8rem',
      fontStyle: 'normal',
      fontFamily: barlowMedium,
    },
    lg: {
      fontSize: '1.6rem',
      lineHeight: '2rem',
    },
  },
  bodyTextCondensed: {
    xs: {
      fontWeight: 500,
      fontSize: '1.4rem',
      lineHeight: '1.8rem',
      fontStyle: 'normal',
      fontFamily: barlowCondensed,
    },
    lg: {
      fontSize: '1.6rem',
      lineHeight: '2rem',
    },
  },
  bodyTextBold: {
    xs: {
      fontWeight: 'normal',
      fontSize: '1.4rem',
      lineHeight: '1.8rem',
      fontStyle: 'normal',
      fontFamily: barlowBold,
    },
    lg: {
      fontSize: '1.6rem',
      lineHeight: '2rem',
    },
  },
  bodyTextSmall: {
    xs: {
      fontWeight: 500,
      fontSize: '1.2rem',
      fontStyle: 'normal',
      lineHeight: '1.6rem',
      fontFamily: barlowMedium,
    },
    lg: {
      fontSize: '1.4rem',
      lineHeight: '1.6rem',
    },
  },
  bodyTextXSmall: {
    xs: {
      fontWeight: 500,
      fontSize: '1.4rem',
      fontStyle: 'normal',
      lineHeight: '1.6rem',
      fontFamily: barlowMedium,
    },
  },
  bodyTextXSmallBold: {
    xs: {
      fontWeight: 500,
      fontSize: '1.4rem',
      fontStyle: 'normal',
      lineHeight: '1.6rem',
      fontFamily: barlowBold,
    },
  },
  bodyTextXXSmall: {
    xs: {
      fontWeight: 500,
      fontSize: '1rem',
      fontStyle: 'normal',
      lineHeight: '1.2rem',
      fontFamily: barlowMedium,
    },
  },
  bodyTextXXXSmall: {
    xs: {
      fontWeight: 500,
      fontSize: '1.2rem',
      fontStyle: 'normal',
      lineHeight: '1.6rem',
      fontFamily: barlowMedium,
    },
  },
  bodyTextXXXSmallBold: {
    xs: {
      fontWeight: 'normal',
      fontSize: '1.2rem',
      fontStyle: 'normal',
      lineHeight: '1.6rem',
      fontFamily: barlowBold,
    },
  },
  bodyTextTinyBold: {
    xs: {
      fontWeight: 'normal',
      fontSize: '0.8rem',
      fontStyle: 'normal',
      lineHeight: '1rem',
      fontFamily: barlowBold,
    },
  },
  bodyTextTinySmall: {
    xs: {
      fontWeight: 'normal',
      fontSize: '0.625rem',
      fontStyle: 'normal',
      lineHeight: '0.75rem',
      fontFamily: barlowMedium,
    },
  },
  bodyTextTinySmallBold: {
    xs: {
      fontWeight: 'normal',
      fontSize: '0.6rem',
      fontStyle: 'normal',
      lineHeight: '1rem',
      fontFamily: barlowBold,
    },
  },
  hideText: {
    xs: {
      fontSize: '0px',
    },
  },
  ...typographyArticle,
};

export default typographyVariants;
