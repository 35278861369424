import styled, { css } from 'styled-components';
import breakpointsMedia from 'theme/utils/breakpointsMedia';
import propToStyle from 'theme/utils/propToStyle';

export const ArticleGridWrapper = styled.div`
  ${({ theme }) => css`
    .swiper {
      padding-bottom: 20px;

      ${breakpointsMedia({
        md: css`
          padding-bottom: 30px;
        `,
        lg: css`
          padding-bottom: 40px;
        `,
      })}

      .article-grid__slider-item {
        width: 33rem;
      }

      .swiper-pagination {
        display: flex;
        height: 0.4rem;
        justify-content: center;
        gap: ${theme.spacings.xs};
        bottom: ${theme.spacings.xs};

        ${breakpointsMedia({
          lg: css`
            display: flex;
            bottom: ${theme.spacings.lg};
          `,
        })}

        .swiper-pagination-bullet {
          margin: 0 3px;
          opacity: 1;
          width: 5%;
          height: 0.4rem;
          border-radius: 0.2rem;
          background-color: ${theme.colors.base['200']};

          ${breakpointsMedia({
            lg: css`
              margin: 0;
              width: 2.4rem;
            `,
          })}

          &.swiper-pagination-bullet-active {
            background-color: ${theme.colors.base['600']};
          }
        }
      }
    }
  `}
`;

export const Row = styled.div`
  ${({ theme }) => css`
    display: flex;
    overflow-x: auto;
    flex-wrap: nowrap;
    gap: ${theme.spacings.sm};

    ${breakpointsMedia({
      lg: css`
        overflow-x: visible;
        gap: ${theme.spacings.md};
      `,
    })}
  `}
`;

export const Col = styled.div`
  ${({ limit }) => css`
    min-width: ${limit === 1 ? '100%' : '33rem'};

    ${breakpointsMedia({
      md: css`
        min-width: auto;
        width: calc(100% / ${limit});
      `,
    })}
  `}

  ${propToStyle({ prop: '$cssMinHeight', css: 'minHeight' })};
`;
