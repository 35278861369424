import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { isEmpty } from 'theme/utils/functions';

import * as S from './styles';

const INITIAL_STATE = {
  localValue: '',
  localControler: false,
};

const Dropdown = ({
  value = '',
  options = [],
  setIndexValue = 0,
  onChange = () => {},
  ariaLabel = 'Selecionar datas',
}) => {
  const [stateLocal, setStateLocal] = useState({
    ...INITIAL_STATE,
    localValue: setIndexValue > 0 ? options[setIndexValue].value : value,
    localControler: isEmpty(value),
  });

  const { localValue, localControler } = stateLocal;

  return (
    <S.DropdownWrapper>
      <S.DropdownContent
        role='combobox'
        aria-label={ariaLabel}
        value={value || localValue}
        $optionActive={value || localValue}
        onChange={e => {
          if (localControler) {
            setStateLocal(state => ({
              ...state,
              localValue: e.target.value,
            }));
          }

          onChange(e);
        }}
      >
        {options.map(option => (
          <S.DropdownItem
            key={option.value}
            value={option.value}
          >
            {option.label}
          </S.DropdownItem>
        ))}
      </S.DropdownContent>
    </S.DropdownWrapper>
  );
};

Dropdown.propTypes = {
  value: PropTypes.string,
  ariaLabel: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  setIndexValue: PropTypes.number.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default React.memo(Dropdown);
