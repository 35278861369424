import React, { useState, useCallback } from 'react';
import Link from 'next/link';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';

import Logo from 'theme/Logo';
import { head, isEmpty, size } from 'theme/utils/functions';
import VARIAVEIS_GLOBAIS from 'theme/utils/globalVariables';

import Text from 'components/foundation/Text';
import Grid from 'components/foundation/layout/Grid';

import * as S from './styles';

const Menu = ({ data = [], contentData = {}, pageUri, customLogoTag }) => {
  const menuData = Object.values(data).sort((a, b) => a.order - b.order);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const router = useRouter();

  const handleSearchVisible = useCallback(() => {
    setIsSearchVisible(!isSearchVisible);
  }, [isSearchVisible]);

  const handleSidebar = useCallback(() => {
    setIsSidebarOpen(!isSidebarOpen);
  }, [isSidebarOpen]);

  const handleSearchSubmit = useCallback(
    e => {
      e.preventDefault();

      if (searchTerm.trim()) {
        if (contentData?.type === 'Category') {
          const firstNews = head(contentData?.Category?.noticias);

          if (!isEmpty(firstNews) && firstNews?.category?.slug) {
            router.push(
              `/busca?keyword=${encodeURIComponent(searchTerm.trim())}&category=${encodeURIComponent(firstNews.category.slug)}`
            );
          } else {
            router.push(
              `/busca?keyword=${encodeURIComponent(searchTerm.trim())}`
            );
          }
        } else {
          router.push(
            `/busca?keyword=${encodeURIComponent(searchTerm.trim())}`
          );
        }
      }
    },
    [searchTerm, contentData?.type, contentData?.Category?.noticias, router]
  );

  const hasContentType = contentData?.type;
  const contentCategory =
    contentData?.Category || contentData?.Article?.category;

  const categoryFatherSlug =
    contentCategory?.categoryFatherSlug ||
    head(contentCategory?.noticias)?.category?.categoryFatherSlug ||
    head(contentCategory?.noticias)?.category?.slug;
  const categoryFatherName =
    contentCategory?.categoryFatherName ||
    head(contentCategory?.noticias)?.category?.categoryFatherName ||
    head(contentCategory?.noticias)?.category?.name;

  let currentCategoryName = contentCategory?.name;
  let currentCategoryData = menuData.find(
    category =>
      category?.title &&
      category.title.toLowerCase() === currentCategoryName?.toLowerCase()
  );

  if (categoryFatherName && categoryFatherSlug) {
    currentCategoryName = categoryFatherName;
    currentCategoryData = menuData.find(
      category =>
        category?.title &&
        category.title.toLowerCase() === currentCategoryName?.toLowerCase()
    );
  }

  const homeCategoryData = menuData.find(
    category => category?.title && category.title.toLowerCase() === 'home'
  );

  let currentCategoryColor = currentCategoryData
    ? (currentCategoryData?.['menu-color'] ?? homeCategoryData?.['menu-color'])
    : homeCategoryData?.['menu-color'];

  let currentSubmenu = hasContentType
    ? currentCategoryData?.submenu
    : homeCategoryData?.submenu;

  if (
    pageUri?.startsWith('/esportes/agenda') ||
    pageUri?.startsWith('/esportes/jogo/futebol')
  ) {
    const dadosCategoryData = menuData?.find(
      category => category?.title?.toLowerCase() === 'agenda'
    );
    if (dadosCategoryData) {
      currentSubmenu = dadosCategoryData?.submenu;
      currentCategoryColor = dadosCategoryData?.['menu-color'];
    }
  }

  return (
    <S.MenuWrapper>
      <S.TopBar>
        <Grid.Container
          $cssHeight='100%'
          $cssDisplay='flex'
          $cssAlignItems='center'
          $cssJustifyContent='space-between'
        >
          <Logo
            width={144}
            height={32}
            customLogoTag={customLogoTag}
          />

          <S.TopBarContent>
            <S.TopBarCategories
              $isSidebarOpen={isSidebarOpen}
              $hasSubMenu={size(currentSubmenu) > 0}
            >
              {menuData?.map((category, index) => (
                <S.CategoryItem
                  key={category?.title}
                  $categoryColor={category['menu-color']}
                >
                  <Link
                    target='_top'
                    prefetch={false}
                    title={category?.title}
                    href={category?.url ?? VARIAVEIS_GLOBAIS.HOST_GAMEARENA}
                  >
                    <Text
                      as='span'
                      $variant={{
                        typography: 'bodyTextBold',
                      }}
                      $csscolor={index === 0 ? '900' : '00'}
                    >
                      {category?.title}
                    </Text>
                  </Link>
                </S.CategoryItem>
              ))}
              <S.SearchButton>
                <button
                  type='button'
                  role='button'
                  onClick={handleSearchVisible}
                  title='Buscar notícias no Game Arena'
                >
                  Abrir barra de pesquisa
                </button>
              </S.SearchButton>
            </S.TopBarCategories>
          </S.TopBarContent>

          <div className='mobile-icons'>
            <S.SearchButton>
              <button
                onClick={handleSearchVisible}
                title='Buscar notícias no Game Arena'
              >
                Abrir barra de pesquisa
              </button>
            </S.SearchButton>

            <S.OpenSidebarButton
              onClick={handleSidebar}
              $isSidebarOpen={isSidebarOpen}
            >
              <span />
            </S.OpenSidebarButton>
          </div>
        </Grid.Container>
      </S.TopBar>

      <S.SearchContainer $isSearchVisible={isSearchVisible}>
        <Grid.Container className='search-container__grid'>
          <form onSubmit={handleSearchSubmit}>
            <Text
              as='span'
              $csscolor='00'
              $variant={{ typography: 'heading3' }}
            >
              Busca
            </Text>
            <input
              type='text'
              value={searchTerm}
              onChange={e => setSearchTerm(e.target.value)}
              placeholder='Pesquisar na Game Arena'
            />
          </form>
        </Grid.Container>
      </S.SearchContainer>

      {size(currentSubmenu) > 0 ? (
        <S.BottomBar $currentCategoryColor={currentCategoryColor}>
          <Grid.Container>
            <S.BottomBarCategories $currentCategoryColor={currentCategoryColor}>
              {currentSubmenu
                .sort((a, b) => a.order - b.order)
                .map(subCategory => (
                  <li key={subCategory?.url}>
                    <Link
                      target='_top'
                      prefetch={false}
                      className='link-underline'
                      title={subCategory?.title}
                      href={
                        subCategory?.url ?? VARIAVEIS_GLOBAIS.HOST_GAMEARENA
                      }
                    >
                      <Text
                        as='span'
                        $csscolor={currentCategoryColor ? '00' : '900'}
                        $variant={{
                          typography: 'bodyTextXSmall',
                        }}
                      >
                        {subCategory?.title}
                      </Text>
                    </Link>
                  </li>
                ))}
            </S.BottomBarCategories>
          </Grid.Container>
        </S.BottomBar>
      ) : null}
    </S.MenuWrapper>
  );
};

Menu.propTypes = {
  pageUri: PropTypes.string,
  customLogoTag: PropTypes.string,
  contentData: PropTypes.shape({}),
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default React.memo(Menu);
